import {Box, Container, Link, Stack, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import {observer} from "mobx-react-lite";
import {DesktopView, useMediaContext} from "../../MediaContext";
import Space from "../Basic/Space";
import Column from "../Basic/Column";
import PictureRender from "../Shared/Pictures/PictureRender";

const textColor = "#4f4f4f";

const TableTopColumn = ({children}) => {
    return <TableCell sx={{
        width: "100%",
        verticalAlign: "top",
        color: textColor,
        borderBottom: "none",
    }}>
        {children}
    </TableCell>
}

const TableBottomColumn = ({children}) => {
    return <TableCell sx={{
        verticalAlign: "bottom",
        color: textColor,
        borderBottom: "none",
    }}>
        {children}
    </TableCell>
}

const FooterWeb = observer(() => {
    const mediaContext = useMediaContext();
    const borderColor = "#bebebe"
    const backgroundColor = "#ebebeb";

    return <Box sx={{
        backgroundColor: backgroundColor,
        borderStyle: "solid none none none",
        borderColor: borderColor,
        marginTop: "50px",
        padding: "20px 0 0px 0"
    }}>
        <Container>
            <Table sx={{tableLayout: "fixed"}}>
                <TableBody>
                    {/* Links row */}
                    <TableRow>

                        {/* First column */}
                        <TableTopColumn>
                            <Stack sx={{alignItems: {xs: "flex-start"}}}>
                                <Typography sx={{fontWeight: "bold"}}>Kontakt og regler</Typography>

                                {/* Kontakt Jagtbasen */}
                                <Link
                                    color="inherit"
                                    underline="hover"
                                    component="button"
                                    onClick={() => window.open(
                                        "/Kontakt-jagtbasen",
                                        "Kontakt-jagtbasen",
                                        "width=600,height=1200")}
                                >
                                    <Typography>Kontakt jagtbasen</Typography>
                                </Link>

                                {/* Annonceringsregler */}
                                <Link
                                    color="inherit"
                                    underline="hover"
                                    component="button"
                                    onClick={() => window.open(
                                        "/regler",
                                        "regler",
                                        "width=600,height=1200")}
                                >
                                    <Typography>Annonceringsregler</Typography>
                                </Link>
                            </Stack>
                        </TableTopColumn>

                        {/* Seconds column */}
                        <TableTopColumn>
                            <Stack sx={{alignItems: {xs: "flex-start"}}}>
                                <Typography sx={{fontWeight: "bold"}}>Vilkår og sikkerhed</Typography>

                                {/* Vilkår og betingelser */}
                                <Link
                                    color="inherit"
                                    underline="hover"
                                    component="button"
                                    onClick={() => window.open(
                                        "/vilkaar-betingelser",
                                        "vilkaar-betingelser",
                                        "width=600,height=1200"
                                    )}
                                >
                                    <Typography>Vilkår og betingelser</Typography>
                                </Link>

                                {/* Sikker handel */}
                                <Link
                                    color="inherit"
                                    underline="hover"
                                    component="button"
                                    onClick={() => window.open(
                                        "/sikker-handel",
                                        "sikker-handel",
                                        "width=600,height=1200")}
                                >
                                    <Typography>Sikker handel</Typography>
                                </Link>

                                {/* Sikker salg */}
                                <Link
                                    color="inherit"
                                    underline="hover"
                                    component="button"
                                    onClick={() => window.open(
                                        "/sikker-salg",
                                        "sikker-salg",
                                        "width=600,height=1200")}
                                >
                                    <Typography>Sikker salg</Typography>
                                </Link>

                                <Link
                                    color="inherit"
                                    underline="hover"
                                    component="button"
                                    onClick={() => window.open(
                                        "/snydet-af-saelger",
                                        "snydet-af-saelger",
                                        "width=600,height=1200")}
                                >
                                    <Typography sx={{textAlign: "left", pr: 2}}>
                                        Jeg er blevet snydt af sælger
                                    </Typography>
                                </Link>
                            </Stack>
                        </TableTopColumn>

                        {/* Third column */}
                        <TableTopColumn>
                            <Stack sx={{alignItems: {xs: "flex-start"}}}>
                                <Typography sx={{fontWeight: "bold"}}>Persondata og cookies</Typography>

                                {/* Persondata- og cookiepolitik */}
                                <Link
                                    color="inherit"
                                    underline="hover"
                                    component="button"
                                    onClick={() => window.open(
                                        "/gdpr-cookies",
                                        "gdpr-cookies",
                                        "width=600,height=1200"
                                    )}
                                >
                                    <Typography sx={{textAlign: "start"}}>Persondata- og cookiepolitik</Typography>
                                </Link>

                                {/* Cookiedeklaration */}
                                <Link
                                    color="inherit"
                                    underline="hover"
                                    component="button"
                                    onClick={() => window.open(
                                        "/cookie-deklaration",
                                        "cookie-deklaration",
                                        "width=800,height=1200")}
                                >
                                    <Typography sx={{textAlign: "left", pr: 2}}>Cookiedeklaration</Typography>
                                </Link>

                            </Stack>
                        </TableTopColumn>

                        {/* Fourth column */}
                        <TableTopColumn>
                            <Stack sx={{alignItems: {xs: "flex-start"}}}>
                                <Typography sx={{fontWeight: "bold"}}>Handel og betaling</Typography>

                                {/* Kvittering og betaling */}
                                <Link
                                    color="inherit"
                                    underline="hover"
                                    component="button"
                                    onClick={() => window.open(
                                        "/kvittering-betaling",
                                        "kvittering-betaling",
                                        "width=600,height=1200")}>
                                    <Typography>Kvittering og betaling</Typography>
                                </Link>

                                {/* Handel med våben */}
                                <Link
                                    color="inherit"
                                    underline="hover"
                                    component="button"
                                    onClick={() => window.open(
                                        "/handel-med-vaaben",
                                        "handel-med-vaaben",
                                        "width=600,height=1200")}
                                >
                                    <Typography>Handel med våben</Typography>
                                </Link>

                                {/* Betalingskort */}
                                <Typography>Betaling:</Typography>
                                <Space height={10}/>
                                <Stack direction={"row"}>
                                    {mediaContext.isUnderLg
                                        ? <PictureRender image="payment-credit-card" size={146} style={{opacity: 0.8}}/>
                                        : <PictureRender image="payment-credit-card" size={176} style={{opacity: 0.8}}/>
                                    }

                                    <Space width={10}/>
                                    {mediaContext.isUnderLg
                                        ? <PictureRender image="payment-mobile-pay-transparent" size={30} />
                                        : <PictureRender image="payment-mobile-pay-transparent" size={37} />
                                    }

                                </Stack>
                            </Stack>
                        </TableTopColumn>

                    </TableRow>

                    {/* Logo and social row */}
                    <TableRow>
                        {/* Logo */}
                        <TableBottomColumn>
                            {mediaContext.isUnderLg
                                ? <PictureRender image={"logo-long-red"} size={175}/>
                                : <PictureRender image={"logo-long-red"} size={225}/>
                            }
                        </TableBottomColumn>

                        <TableBottomColumn></TableBottomColumn>
                        <TableBottomColumn></TableBottomColumn>

                        {/* Social */}
                        <TableBottomColumn>
                            <Column>
                                <Typography sx={{fontWeight: "bold", fontSize: "16px"}}>Følg os på </Typography>
                                <Link
                                    color="inherit"
                                    underline="hover"
                                    component="button"
                                    onClick={() => window.location.href = "https://www.facebook.com/profile.php?id=100092620755784"}
                                >
                                    <FacebookIcon fontSize="large"/>
                                </Link>
                                <Link
                                    color="inherit"
                                    underline="hover"
                                    component="button"
                                    onClick={() => window.location.href = "https://www.instagram.com/jagtbasen/"}
                                >
                                    <InstagramIcon fontSize="large"/>
                                </Link>

                            </Column>
                        </TableBottomColumn>
                    </TableRow>
                </TableBody>
            </Table>
        </Container>
    </Box>;
});

function Footer() {
    return <Box sx={{marginTop:'auto'}}>
        <FooterWeb/>
    </Box>
}

export default observer(Footer);